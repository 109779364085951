import { useCallback } from 'react'

export function PaymentInput({
	purchaseAmount,
	setPurchaseAmount,
}: {
	purchaseAmount: number
	setPurchaseAmount: React.Dispatch<React.SetStateAction<number>>
}) {
	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = Number.parseInt(event.target.value)
			if (!Number.isNaN(value) && value >= 0) {
				setPurchaseAmount(value)
			}
		},
		[setPurchaseAmount]
	)

	return (
		<>
			<label className="-mb-4 text-sm text-white-500/50" htmlFor="price">
				Enter purchase amount
			</label>
			<div className="relative space-x-8 shadow-sm">
				<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-0">
					<span className="text-3xl text-white-500/50">kr</span>
				</div>
				<input
					type="number"
					name="price"
					id="price"
					min="0"
					step="1"
					className="focus:ring-3 block rounded-md border-0 bg-transparent py-1.5 pl-7 pr-12 text-5xl text-white caret-aquamarine-600 ring-0 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-0 focus:ring-aquamarine-600 sm:leading-6"
					value={purchaseAmount}
					aria-describedby="price-currency"
					onChange={handleInputChange}
				/>
			</div>
		</>
	)
}
