import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo } from 'react'

interface PaymentButtonProperties {
	onClick: () => void
	loading: boolean
	label: string
	loadingLabel: string
	disabled: boolean
	keyboardShortcut?: string
}

/**
 * Determines the opacity of the button based on its loading and disabled states.
 *
 * @param loading - Whether the button is in a loading state.
 * @param disabled - Whether the button is disabled.
 * @returns The opacity of the button.
 */
function getButtonOpacity(loading: boolean, disabled: boolean): number {
	return loading ? 0.5 : disabled ? 0.2 : 1
}

/**
 * Determines the scale of the button based on its loading and disabled states.
 *
 * @param loading - Whether the button is in a loading state.
 * @param disabled - Whether the button is disabled.
 * @returns The scale of the button.
 */
function getButtonScale(loading: boolean, disabled: boolean): number {
	return loading ? 1.2 : disabled ? 0 : 1
}

/**
 * PaymentButton component.
 *
 * @param {PaymentButtonProperties} props - The properties of the PaymentButton component.
 * @returns {JSX.Element} The rendered PaymentButton component.
 */
export function PaymentButton({
	onClick,
	loading,
	label,
	loadingLabel,
	disabled,
	keyboardShortcut,
}: Readonly<PaymentButtonProperties>): JSX.Element {
	// Initial animation properties for the button
	const initialAnimation = useMemo(() => ({ opacity: 0, scale: 0 }), [])
	// Animation properties for the button based on its loading and disabled states
	const animateProperties = useMemo(
		() => ({
			opacity: getButtonOpacity(loading, disabled),
			scale: getButtonScale(loading, disabled),
			rotate: loading ? -10 : 0,
		}),
		[loading, disabled]
	)
	// Transition properties for the button animation
	const transitionProperties = useMemo(() => ({ type: 'spring', stiffness: 300, damping: 20 }), [])

	// Event handler for key press events
	const handleKeyPress = useCallback(
		(event: KeyboardEvent) => {
			// Check if any of the command, ctrl, alt keys are pressed
			const isModifierKey = event.metaKey || event.ctrlKey || event.altKey
			// If the pressed key matches the keyboard shortcut and the button is not disabled, trigger the onClick event
			if (!isModifierKey && event.key === keyboardShortcut && !disabled) {
				onClick()
			}
		},
		[keyboardShortcut, disabled, onClick]
	)

	// Add event listener for key press events
	useEffect(() => {
		window.addEventListener('keypress', handleKeyPress)
		return () => window.removeEventListener('keypress', handleKeyPress)
	}, [handleKeyPress])

	return (
		<motion.button
			type="button"
			className="rounded-full bg-aquamarine-600 px-4 py-2 text-sm font-medium text-primary-500 hover:bg-aquamarine-800 focus:outline-none focus:ring-2 focus:ring-aquamarine-500 focus:ring-offset-2"
			onClick={onClick}
			disabled={disabled}
			initial={initialAnimation}
			animate={animateProperties}
			transition={transitionProperties}
		>
			{loading ? loadingLabel : label}
		</motion.button>
	)
}
