import { useCallback, useEffect, useRef } from 'react'
import { availablePointsOfInterest } from '../data/availablePOI'
import { PaymentButton } from './PaymentButton'

/** Interface for the properties of the PaymentButtons component */
interface PaymentButtonsProperties {
	sendPaymentRequest: () => void
	sendCardAcquisitionRequest: () => void
	paymentLoading: boolean
	acquisitionLoading: boolean
	selectedPOI: string
	setSelectedPOI: React.Dispatch<React.SetStateAction<string>>
	handleCancelRequest?: () => void
}

/** Component for rendering payment buttons */
export function PaymentButtons({
	sendPaymentRequest,
	sendCardAcquisitionRequest,
	paymentLoading,
	acquisitionLoading,
	selectedPOI,
	setSelectedPOI,
	handleCancelRequest,
}: Readonly<PaymentButtonsProperties>) {
	const isDisabled = paymentLoading || acquisitionLoading || selectedPOI === ''
	const isCancelDisabled = selectedPOI === ''

	/** Handler for select change events */
	const handleSelectChange = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) => {
			setSelectedPOI(event.target.value)
		},
		[setSelectedPOI]
	)

	// Reference to the select element
	const selectReference = useRef<HTMLSelectElement>(null)

	// Effect to focus the select element on component mount
	useEffect(() => {
		// If the select element is available, focus it
		if (selectReference.current) {
			selectReference.current.focus()
		}
	}, [])

	return (
		<div className="flex flex-col space-y-4">
			<label htmlFor="availablePOI" className="sr-only text-white">
				Available POI
			</label>
			<select
				ref={selectReference}
				name="availablePOI"
				id="availablePOI"
				className="rounded-md border border-gray-500 bg-transparent px-3 py-2 text-white focus:ring-2 focus:ring-aquamarine-500"
				defaultValue={selectedPOI}
				onChange={handleSelectChange}
			>
				<option value="">Select POI</option>
				{availablePointsOfInterest.map((poi) => (
					<option key={poi.id} value={poi.id}>
						{poi.model} - {poi.location}
					</option>
				))}
			</select>
			<div className="flex space-x-4">
				<PaymentButton
					onClick={sendPaymentRequest}
					loading={paymentLoading}
					label="Payment"
					loadingLabel="Waiting for payment..."
					disabled={isDisabled}
					keyboardShortcut="p"
				/>
				<PaymentButton
					onClick={sendCardAcquisitionRequest}
					loading={acquisitionLoading}
					label="Card Acquisition"
					loadingLabel="Waiting for card acquisition..."
					disabled={isDisabled}
					keyboardShortcut="c"
				/>
				{handleCancelRequest ? (
					<PaymentButton
						label="Cancel"
						loading={false}
						loadingLabel=""
						onClick={handleCancelRequest}
						disabled={isCancelDisabled}
					/>
				) : (
					false
				)}
			</div>
		</div>
	)
}
