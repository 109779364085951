'use client'

// eslint-disable-next-line unicorn/prefer-node-protocol
import crypto from 'crypto'

import { sendCancelRequest, sendCardAcquisitionRequest, sendPaymentRequest } from '@/utils/adyenUtils'
import { MessageReference } from '@adyen/api-library/lib/src/typings/terminal/models'
import { TerminalApiResponse } from '@adyen/api-library/lib/src/typings/terminal/terminalApiResponse'
import { AnimatePresence, motion } from 'framer-motion'
import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
import ReactConfetti from 'react-confetti'
import { AdyenResults } from '../components/AdyenResults'
import { Footer } from '../components/Footer'
import { Logo } from '../components/Logo'
import { NorthernLights } from '../components/NorthernLights'
import { PaymentButtons } from '../components/PaymentButtons'
import { PaymentInput } from '../components/PaymentInput'
import { extractLoyaltyId, extractLoyaltyProgram } from '../utils/loyaltyUtils'

const CONFETTI_COLORS = [
	'#c0fff1',
	'#b6ffe7',
	'#acffdd',
	'#a2ffd3',
	'#98ffc9',
	'#8efebf',
	'#7aeaab',
	'#70e0a1',
	'#66d697',
]

const RESET_IDENTIFIED_DELAY_MS = 7000

const CONFETTI_ANIMATION = {
	INITIAL: { opacity: 0 },
	ANIMATE: { opacity: 1 },
	EXIT: { opacity: 0 },
	TRANSITION: { duration: 1 },
}

const HEADING_ANIMATION = {
	INITIAL: { opacity: 0, x: -150, y: -150, scale: 0 },
	ANIMATE: { opacity: 1, x: 0, y: 0, scale: 1 },
	EXIT: { opacity: 0, x: -50, y: -50 },
	TRANSITION: { duration: 2, type: 'spring', stiffness: 100, damping: 20, mass: 2 },
}

const GRAPHQL_ENDPOINT = process.env.GRAPHQL_API_URL ?? ''
const GRAPHQL_API_KEY = process.env.GRAPHQL_API_KEY ?? ''

/**
 * Generates a unique service ID using crypto.
 *
 * @param {number} bytes - The number of bytes to be used in the ID generation.
 * @returns {string} The newly generated service ID.
 * @throws {Error} If an error occurs during ID generation.
 */
function generateServiceId(bytes = 4): string {
	try {
		console.log('Generating service id')
		return crypto.randomBytes(bytes).toString('hex')
	} catch (error) {
		console.error('Error generating service id:', error)
		throw new Error('Failed to generate service ID')
	}
}

/**
 * Looks up a customer using the GraphQL API.
 *
 * @param {string} customerId - The ID of the customer to look up.
 * @returns {Promise<string | undefined>} The customer's display name, if found.
 */
async function lookupCustomer(customerId: string) {
	const query = `
    query getCustomer {
      getPass(id: "${customerId}") {
        customer {
          displayName
        }
      }
    }
  `

	const response = await fetch(GRAPHQL_ENDPOINT, {
		method: 'POST',
		body: JSON.stringify({ query }),
		headers: {
			'Content-Type': 'application/graphql',
			'x-api-key': GRAPHQL_API_KEY,
		},
	})

	const result = (await response.json()) as { data?: { getPass?: { customer?: { displayName?: string } } } }
	return result.data?.getPass?.customer?.displayName
}

export default function IndexPage() {
	const [paymentLoading, setPaymentLoading] = useState(false)
	const [acquisitionLoading, setAcquisitionLoading] = useState(false)
	const [terminalResponse, setTerminalResponse] = useState<TerminalApiResponse | undefined>()
	const [purchaseAmount, setPurchaseAmount] = useState(1)
	const [selectedPOI, setSelectedPOI] = useState('')
	const [identified, setIdentified] = useState(false)
	const [customerName, setCustomerName] = useState('')
	const [responseHidden, setResponseHidden] = useState(true)
	const [serviceId, setServiceId] = useState('')
	const [requestType, setRequestType] = useState<MessageReference.MessageCategoryEnum>(
		MessageReference.MessageCategoryEnum.Payment
	)

	useEffect(() => {
		setServiceId(generateServiceId())
	}, [])

	/**
	 * Sets the identified state to true and resets it to false after a specified duration.
	 *
	 * @param {string} name - The name of the customer. Defaults to 'Unknown Customer'.
	 */
	function setIdentifiedAndReset(name = 'Unknown Customer') {
		console.log('Loyalty program and ID found, setting identified to true')
		setIdentified(true)
		setCustomerName(name)

		const timeoutId = setTimeout(() => {
			console.log('Resetting identified to false after 7 seconds')
			setIdentified(false)
			setCustomerName('')
		}, RESET_IDENTIFIED_DELAY_MS)

		return () => clearTimeout(timeoutId)
	}

	useEffect(() => {
		async function checkForLoyaltyPrograms() {
			try {
				console.log('Checking for loyalty programs in the payment and acquisition responses')

				const program = extractLoyaltyProgram(terminalResponse)
				if (!program) {
					console.log('No loyalty program found in the payment or acquisition responses')
					return
				}

				const loyaltyId = extractLoyaltyId(program)
				if (!loyaltyId) {
					setIdentified(false)
					setCustomerName('')
					return
				}

				const name = await lookupCustomer(loyaltyId)
				setIdentifiedAndReset(name)
			} catch (error) {
				console.error('Error in checking for loyalty programs:', error)
			}
		}

		void checkForLoyaltyPrograms()
	}, [terminalResponse])

	useEffect(() => {
		setTerminalResponse(undefined)
	}, [selectedPOI])

	/** Handles the payment request. */
	async function handlePaymentRequest(): Promise<void> {
		const newServiceId = generateServiceId()
		console.log('Payment Service id', newServiceId)
		setRequestType(MessageReference.MessageCategoryEnum.Payment)
		await sendPaymentRequest(
			purchaseAmount,
			selectedPOI,
			newServiceId,
			setTerminalResponse,
			setPaymentLoading
		)
	}

	/** Handles the card acquisition request. */
	async function handleCardAcquisitionRequest(): Promise<void> {
		const newServiceId = generateServiceId()
		console.log('Card Acquisition Service id', newServiceId)
		setRequestType(MessageReference.MessageCategoryEnum.CardAcquisition)
		await sendCardAcquisitionRequest(
			selectedPOI,
			setTerminalResponse,
			setAcquisitionLoading,
			purchaseAmount,
			newServiceId,
			false
		)
	}

	/** Handles the cancel request. */
	async function handleCancelRequest(): Promise<void> {
		console.log('Cancel Service id', serviceId)
		console.log('Cancel Request Type', requestType)
		await sendCancelRequest(selectedPOI, serviceId, requestType)
	}

	/** Toggles the visibility of the response. */
	function handleSetResponseHidden() {
		setResponseHidden((previousHidden) => !previousHidden)
	}

	const welcomeMessage = useMemo(
		() =>
			customerName ? (
				<motion.h1
					className="mt-14 text-4xl font-bold tracking-tight text-white sm:text-6xl md:mt-24"
					initial={HEADING_ANIMATION.INITIAL}
					animate={HEADING_ANIMATION.ANIMATE}
					exit={HEADING_ANIMATION.EXIT}
					transition={HEADING_ANIMATION.TRANSITION}
				>
					Welcome back,
					<br />
					<span className="bg-gradient-to-r from-[#c0fff1] to-[#66d697] bg-clip-text text-transparent">
						{customerName} 👋🏼
					</span>{' '}
				</motion.h1>
			) : (
				<h1 className="mt-14 text-4xl font-bold tracking-tight text-white sm:text-6xl md:mt-24">
					Discover the Stellar Universe of{' '}
					<span className="bg-gradient-to-r from-[#c0fff1] to-[#66d697] bg-clip-text text-transparent">
						Stell ✨
					</span>
				</h1>
			),
		[customerName]
	)

	return (
		<main>
			<NorthernLights />

			<div className="relative isolate mx-auto max-w-7xl overflow-hidden px-6 py-10 sm:pb-8 lg:flex lg:px-8 lg:pt-20">
				<div className="mx-auto max-w-2xl shrink-0 lg:mx-0 lg:max-w-2xl lg:pt-8">
					<Link href="/" title="Stell">
						<Logo className="h-8 fill-white" />
					</Link>
					<AnimatePresence>{welcomeMessage}</AnimatePresence>
					<p className="text-md mt-6 space-y-2 leading-8 text-white-500/70">
						Step into the future with Stell, where the universe of customer identification and payments
						unfolds seamlessly.
					</p>

					<div className="items-leading mt-10 flex flex-col gap-y-6">
						<PaymentInput purchaseAmount={purchaseAmount} setPurchaseAmount={setPurchaseAmount} />
						<PaymentButtons
							sendPaymentRequest={handlePaymentRequest}
							sendCardAcquisitionRequest={handleCardAcquisitionRequest}
							paymentLoading={paymentLoading}
							acquisitionLoading={acquisitionLoading}
							selectedPOI={selectedPOI}
							setSelectedPOI={setSelectedPOI}
							handleCancelRequest={handleCancelRequest}
						/>
					</div>
				</div>
			</div>

			<button
				type="button"
				className="absolute right-0 top-0 mr-4 mt-4 text-white/10 hover:text-white/100"
				onClick={handleSetResponseHidden}
			>
				⚠︎ {responseHidden ? 'Show' : 'Hide'} response
			</button>

			{!responseHidden && (
				<AdyenResults formattedResponse={terminalResponse} isPaymentComplete={!!terminalResponse} />
			)}

			<AnimatePresence>
				{identified ? (
					<motion.div
						initial={CONFETTI_ANIMATION.INITIAL}
						animate={CONFETTI_ANIMATION.ANIMATE}
						exit={CONFETTI_ANIMATION.EXIT}
						transition={CONFETTI_ANIMATION.TRANSITION}
					>
						<ReactConfetti gravity={0.5} colors={CONFETTI_COLORS} />
					</motion.div>
				) : (
					false
				)}
			</AnimatePresence>

			<Footer />
		</main>
	)
}
