import { MessageReference, TerminalApiResponse } from '@adyen/api-library/lib/src/typings/terminal/models'

/** API endpoints for the Adyen service. */
const API_ENDPOINTS = {
	CARD_ACQUISITION: '/api/cardacquisition',
	PAYMENT: '/api/payment',
	CANCEL: '/api/cancel',
}

/**
 * Asynchronously sends a card acquisition request to the server. Updates the payment response and loading
 * state based on the server's response.
 *
 * @param {string} selectedPOI - The selected POI.
 * @param {Function} setPaymentResponse - The setter function for payment response.
 * @param {Function} setAcquisitionLoading - The setter function for acquisition loading.
 * @returns {Promise<void>}
 */
export async function sendCardAcquisitionRequest(
	selectedPOI: string,
	setPaymentResponse: (response: TerminalApiResponse | undefined) => void,
	setAcquisitionLoading: (loading: boolean) => void,
	purchaseAmount: number,
	serviceId: string,
	vasOnly = false
): Promise<void> {
	// Reset the payment response and set loading to true

	setPaymentResponse(undefined)
	setAcquisitionLoading(true)

	try {
		// Send a POST request to the server
		const response = await fetch(
			`${API_ENDPOINTS.CARD_ACQUISITION}?POIID=${selectedPOI}&amount=${purchaseAmount}&serviceId=${serviceId}&vasOnly=${vasOnly}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

		console.info('Card acquisition request response', response)

		// Check if the response is ok
		if (!response.ok) {
			throw new Error(`Card acquisition request failed with status: ${response.status}`)
		}

		// Parse the response data
		const responseData: TerminalApiResponse = (await response.json()) as TerminalApiResponse

		console.info('Card acquisition request data', responseData)

		// Update the payment response
		setPaymentResponse(responseData)
	} catch (error) {
		console.error('Error sending card acquisition request', error)
		setPaymentResponse(undefined)
	} finally {
		// Set loading to false
		setAcquisitionLoading(false)
	}
}

/**
 * Asynchronously sends a payment request to the server. Updates the payment response and loading state based
 * on the server's response.
 *
 * @param {number} purchaseAmount - The amount to purchase.
 * @param {string} selectedPOI - The selected POI.
 * @param {Function} setPaymentResponse - The setter function for payment response.
 * @param {Function} setPaymentLoading - The setter function for payment loading.
 * @returns {Promise<void>}
 */
export async function sendPaymentRequest(
	purchaseAmount: number,
	selectedPOI: string,
	serviceId: string,
	setPaymentResponse: (response: TerminalApiResponse | undefined) => void,
	setPaymentLoading: (loading: boolean) => void
): Promise<void> {
	// Reset the payment response and set loading to true

	setPaymentResponse(undefined)
	setPaymentLoading(true)

	try {
		// Send a POST request to the server with the purchase amount
		const response = await fetch(
			`${API_ENDPOINTS.PAYMENT}?amount=${purchaseAmount}&POIID=${selectedPOI}&serviceId=${serviceId}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

		console.info('Payment request response', response)

		// Check if the response is ok
		if (!response.ok) {
			throw new Error(`Payment request failed with status: ${response.status}`)
		}

		// Parse the response data
		const responseData: TerminalApiResponse = (await response.json()) as TerminalApiResponse

		console.info('Payment response data', responseData)

		// Update the payment response
		setPaymentResponse(responseData)
	} catch (error) {
		console.error('Error sending payment request', error)
		// Set payment response to undefined in case of error
		setPaymentResponse(undefined)
	} finally {
		// Set loading to false
		setPaymentLoading(false)
	}
}

/**
 * Asynchronously sends a cancel request to the server.
 *
 * @param {string} selectedPOI - The selected POI.
 * @param {string} serviceId - The service ID of the transaction you want to cancel.
 * @returns {Promise<void>}
 */
export async function sendCancelRequest(
	selectedPOI: string,
	serviceId: string,
	messageCategory: MessageReference.MessageCategoryEnum
): Promise<void> {
	try {
		// Log the start of the cancel request process
		console.debug('Preparing to send cancel request')

		// Send a POST request to the server with the selected POI and service ID
		const response = await fetch(
			`${API_ENDPOINTS.CANCEL}?POIID=${selectedPOI}&serviceId=${serviceId}&messageCategory=${messageCategory}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

		// Log that the cancel request has been sent
		console.debug('Cancel request sent')

		// Log the response from the cancel request
		console.info('Cancel request response', response)

		// If the response is not ok, log an error and return
		if (!response.ok) {
			console.error('Cancel request failed')
			return
		}
	} catch (error) {
		// Log any errors that occur during the cancel request process
		console.error('Error sending cancel request', error)
	}
}
