import { TerminalApiResponse } from '@adyen/api-library/lib/src/typings/terminal/models'
import { motion } from 'framer-motion'
import { useMemo } from 'react'

const initialMotion = { opacity: 0 }
const transitionMotion = { duration: 0.5 }

export function AdyenResults({
	formattedResponse: terminalResponse,
	isPaymentComplete,
}: Readonly<{
	formattedResponse: TerminalApiResponse | undefined
	isPaymentComplete: boolean
}>) {
	const animateMotion = useMemo(() => ({ opacity: isPaymentComplete ? 1 : 0 }), [isPaymentComplete])

	return (
		<motion.div initial={initialMotion} animate={animateMotion} transition={transitionMotion}>
			{isPaymentComplete ? (
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<div className="mx-auto max-w-7xl sm:text-center">
						<pre className="mt-6 overflow-x-auto whitespace-pre-wrap rounded-md p-4 text-left text-lg leading-8 text-gray-300">
							{JSON.stringify(terminalResponse, undefined, 4)}
						</pre>
					</div>
				</div>
			) : (
				false
			)}
		</motion.div>
	)
}
